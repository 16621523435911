@import 'button';
/* @import 'swiper'; */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* purgecss start ignore */

.z-3{
  z-index: 3;
}


html{
    scroll-behavior:smooth;
    }

    .overlay-video {
  display: none;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
  -ms-transition: opacity 600ms ease-in;
  transition: opacity 600ms ease-in;
  -ms-transition: opacity .6s;
  transition: opacity .6s;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, .7);
  z-index: 999999;
}

.o1 {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1;
  -ms-transition: opacity 600ms ease-out;
  transition: opacity 600ms ease-out;
  -ms-transition: opacity .6s;
  transition: opacity .6s;
}

.videoWrapperExt {
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 1440px;
  padding: 0 20px;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.close {
 /* background-image:url(http://djit.ac/assets/images/news/mark.png); */
  position: absolute;
  top: -50px;
  right: 0px;
  cursor: pointer;
  z-index: 9999;
 
  background-size: 40px;
  @media (max-width: 767px) and (orientation: landscape) {
    display: none;
  }
}

/* .bg-gray{
  background-color: #E5E5E5;
} */


/* .nothing{
  @apply bg-gray;
} */

/* purgecss end ignore */